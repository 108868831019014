import React, { useState } from 'react';
import './navbarSite.css';
import logo from '../../assets/Ersthelfer_Logo.png';
import contactImg from '../../assets/Ersthelfer_Kontakt.png';
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';
import menu from '../../assets/menu.png';

export const NavbarSite = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <nav className="navbarLogo">
      <NavLink style={{ textDecoration: 'none'}} to='/' /* id='events' */>
        <img src={logo} alt="Logo" className='logo'/>
        </NavLink>
        <div className="desktopMenu">
          
            {/* <Link activeClass='active' to='intro' spy={true} smooth={true} offset={-200} duration={500} className="desktopMenuListItem">Home</Link> */}
          
           {/*  <Link activeClass='active' to='aboutus' spy={true} smooth={true} offset={-120} duration={500} className="desktopMenuListItem">Über Uns</Link>
            <Link activeClass='active' to='events' spy={true} smooth={true} offset={-150} duration={500} className="desktopMenuListItem">Events</Link>
            <Link activeClass='active' to='downloads' spy={true} smooth={true} offset={-50} duration={500} className="desktopMenuListItem">Downloads</Link> */}
        </div> 
        {/* <button className="desktopMenuBtn" onClick={() => {
          document.getElementById('contact').scrollIntoView({behavior: 'smooth'});
        }}>
            <img src={contactImg} alt="" className="desktopMenuImg" />Kontaktiere Uns</button> */}


        {/* <img src={menu} alt="Menu" className='mobMenu'onClick={()=>setShowMenu(!showMenu)}/>
        <div className="navMenu" style={{display: showMenu? 'flex':'none'}}>
            <Link activeClass='active' to='intro' spy={true} smooth={true} offset={-200} duration={500} className="listItem" onClick={()=>setShowMenu(false)}>Home</Link>
            <Link activeClass='active' to='aboutus' spy={true} smooth={true} offset={-200} duration={500} className="listItem" onClick={()=>setShowMenu(false)}>About</Link>
            <Link activeClass='active' to='works' spy={true} smooth={true} offset={-50} duration={500} className="listItem" onClick={()=>setShowMenu(false)}>Portfolio</Link>
            <Link activeClass='active' to='clients' spy={true} smooth={true} offset={-50} duration={500} className="listItem" onClick={()=>setShowMenu(false)}>Clients</Link>
            <Link activeClass='active' to='contact' spy={true} smooth={true} offset={-50} duration={500} className="listItem" onClick={()=>setShowMenu(false)}>Contact</Link>
        </div> */}
    </nav>
  )
}
