import React from 'react';
import './datenschutz.css';
import TauernsoundImg from '../../assets/Tauernsound_Image.png'


export const Datenschutz = () => {
  return (
        <section id="datenschutz">
            <h1 className='datenschutzTitle'>Datenschutz</h1>
            <div className="datenschutzBody">
              <div className="datenschutzText">
                <p className='datenschutzP'>Nutzungsbedingungen <br/>

Die Inhalte dieser Web-Site sind frei nutzbar und dienen ausschließlich der Information. Es kommen durch die Nutzung der Auswahlmöglichkeiten der Web-Site keinerlei Rechtsgeschäfte zwischen dem Eigentümer und den Nutzern zu Stande. Die weitere Vorgangsweise zwischen Anbietern und Nutzern und der mögliche spätere Abschluss von Aufträgen zwischen Anbietern und Nutzern liegen im ausschließlichen Ermessen der Nutzer.
<br/>
Copyright / Haftungsausschluss <br/>

Im Hinblick auf die technischen Eigenschaften des Internet kann keine Gewähr für die Authentizität, Richtigkeit und Vollständigkeit der im Internet zur Verfügung gestellten Informationen übernommen werden. Es wird auch keine Gewähr für die Verfügbarkeit oder den Betrieb der gegenständlichen Homepage und ihrer Inhalte übernommen.

Jede Haftung für unmittelbare, mittelbare oder sonstige Schäden, unabhängig von deren Ursachen, die aus der Benutzung oder Nichtverfügbarkeit der Daten und Informationen dieser Homepage erwachsen, wird, soweit rechtlich zulässig, ausgeschlossen.

Der Inhalt dieser Homepage ist urheberrechtlich geschützt. Die Informationen sind nur für die persönliche Verwendung bestimmt. Jede weitergehende Nutzung insbesondere die Speicherung in Datenbanken, Vervielfältigung und jede Form von gewerblicher Nutzung sowie die Weitergabe an Dritte - auch in Teilen oder in überarbeiteter Form - ohne Zustimmung des Website-Inhabers ist untersagt.

Hyperlinks auf diese Homepage sind willkommen. Jede Einbindung einzelner Seiten unseres Angebotes in fremde Frames ist zu unterlassen.
<br/>
Datenschutz <br/>

Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
<br/>
Haftung für Links <br/>

Diese Homepage enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei bekannt werden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
              </div>
            </div>
            
        </section>
  )
}
