import React from 'react';
import './partner.css';

import Sponsor1 from '../../assets/Sponsor_SeniorenheimWindegger.png';
import Sponsor2 from '../../assets/Sponsor_Confida.png';
import Sponsor3 from '../../assets/Sponsor_Mueller.png';

export const Partner = () => {


  return (
        <section id="partner">
            <h1 className='partnerTitle'>Danke an unsere Sponsoren</h1>
            <p className='partnerSmall'>Wenn auch Sie uns unterstützen möchten nehmen Sie bitte mit uns Kontakt auf. <br />Email: ersthelfer.band@gmail.com <br />Telefonnummer: 0664 88592786</p>
            <div className="partnerBody">
              <div className="partner">
                <img className='partnerImg' src={Sponsor1} alt="Seniorenheim Windegger" />
                <p className='partnerText'>Seniorenheim Windegger</p>
                <a className='partnerLink' href="http://www.windegger.at/" target="_blank">www.windegger.at</a>
              </div>
              <div className="partner">
                <img className='partnerImg' src={Sponsor2} alt="Seniorenheim Windegger" />
                <p className='partnerText'>Confida Steuerberatung</p>
                <a className='partnerLink' href="https://www.confida.at/" target="_blank">www.confida.at</a>
              </div>
              <div className="partner">
                <img className='partnerImg' src={Sponsor3} alt="Seniorenheim Windegger" />
                <p className='partnerText'>Harmonika Müller</p>
                <a className='partnerLink' href="https://www.harmonika.at/home/" target="_blank">www.harmonika.at</a>
              </div>
            </div>
            
        </section>
  )
}
