import React from 'react';
import './aboutus.css';
import img2 from '../../assets/Ersthelfer_Foto_1.1.png';
import TauernsoundLogo from '../../assets/Tauernsound_Logo.png';

import Lukas from '../../assets/Lukas.png';
import Stefan from '../../assets/Stefan.png';
import Johann from '../../assets/Johann2.png';
import Thomas from '../../assets/Thomas.png';
import Sofia from '../../assets/Sofia.png';

import { NavLink } from 'react-router-dom';


/* import {Link} from 'react-scroll'; */

export const AboutUs = () => {
  return (
        <section id="aboutus">
          <div className="nextto">
            <div className="aboutusContent">
                {/* <span className="aboutusText">Wir sind die <span className="aboutusName">Ersthelfer</span> <br /></span> */}
                <p className="aboutusPara">Wir sind die <span className="aboutusName">Ersthelfer</span> - eine energiegeladene Band aus der Steiermark, bestehend aus talentierten Musikern, die ihre Leidenschaft für die Musik in jede Performance einfließen lassen. Wir versetzen unser Publikum, egal ob jung oder alt, mit unserem einzigartigen Sound und mitreißendem Entertainment in Begeisterung. <br/>Unser breit gefächertes Repertoire reicht von aktuellen Chart-Hits über Rock und Pop bis hin zu Schlager und fetziger Volksmusik - ein Mix, der für jeden Geschmack etwas bietet und die Besucher zum Feiern und Tanzen animiert. <br/>Mit 100%iger Livemusik, selbst kreiertem Synth-Bass Sound auf der steirischen Harmonika und professionellem Equipment samt dazugehöriger Tontechnik ist es uns möglich, für jede Veranstaltung die beste Qualität und Einzigartigkeit zu bieten.</p>
                
            </div> 
            <img src={img2} alt="Profile" className='img2' />
            </div>

            <div className="worksImgs">
              
              <div className="wrapper">
              <img src={Lukas} alt="" className="PeopleImg" />
              <div className="content">
              <p className='PeopleName'>Lukas Sattler</p>
              <p className='PeopleInfo'>Harmonika, E-Gitarre, Gesang</p>
              </div>
              </div>

              <div className="wrapper">
              <img src={Stefan} alt="" className="PeopleImg" />
              <div className="content">
              <p className='PeopleName'>Stefan Schneidl</p>
              <p className='PeopleInfo'>Harmonika, E-Bass, Posaune, Gesang</p>
              </div>
              </div>
              <div className="wrapper">
              <img src={Thomas} alt="" className="PeopleImg" />
              <div className="content">
              <p className='PeopleName'>Thomas Gruber</p>
              <p className='PeopleInfo'>E-Gitarre, Gesang</p>
              </div>
              </div>
              <div className="wrapper">
              <img src={Johann} alt="" className="PeopleImg" />
              <div className="content">
              <p className='PeopleName'>Johann Spiegel</p>
              <p className='PeopleInfo'>Schlagzeug</p>
              </div>
              </div>
              <div className="wrapper">
              <img src={Sofia} alt="" className="PeopleImg" />
              <div className="content">
              <p className='PeopleName'>Sofia Roth</p>
              <p className='PeopleInfo'>Gitarre, Gesang</p>
              </div>
              </div>
              {/* <img src={Stefan} alt="" className="PeopleImg" />
              <img src={Thomas} alt="" className="PeopleImg" />
              <img src={Johann} alt="" className="PeopleImg" />
              <img src={Sofia} alt="" className="PeopleImg" /> */}
            </div>
            <div className="tauernsoundLinks">
                    <NavLink style={{ textDecoration: 'none'}} to='/tauernsound'>
                    <button  className="tauernsoundBtn1">Hier geht’s zu unserem Tontechniker</button>
                    </NavLink>
                </div>
        </section>
  )
}
