import React from 'react';
import './tauernsound.css';
import TauernsoundImg from '../../assets/Tauernsound_Image.png'


export const Tauernsound = () => {


  return (
        <section id="tauernsound">
            <h1 className='tauernsoundTitle'>Tauernsound</h1>
            <div className="tauernsoundBody">
              <div className="tauernsoundText">
                <p className='tauernsoundP'>Sepp Schwarz ist der kreative Kopf hinter dem einzigartigen Klang unserer Band. Mit geschultem Ohr und einer Leidenschaft für Perfektion kreiert er den „Ersthelfer-Sound“.
Studio und Live unteranderem bei „Meilenstein“ und „Schwoazstoaner“. 
Sepp sorgt dafür, dass unsere Musik nicht nur gehört, sondern auch gefühlt wird.</p>
                <a href="https://www.tauernsound.com/" target="_blank">
                <button  className="tauernsoundBtn">Hier geht’s zur Website</button>
                </a>
              </div>
              <div className="tauernsoundImage">
                <img src={TauernsoundImg} alt="TauernsoundImage" className='tauernsoundImg'/>
              </div>
            </div>
            
        </section>
  )
}
