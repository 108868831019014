import React, { useState } from 'react';
import './navbar.css';
import logo from '../../assets/Ersthelfer_Logo.png';
import contactImg from '../../assets/Ersthelfer_Download.png';
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';
import menu from '../../assets/menu.png';

export const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <nav className="navbar">
        <img src={logo} alt="Logo" className='logo'/>
        {/* <Link textDecoration='none' to='intro' spy={true} smooth={true} offset={-200} duration={500}><img src={logo} alt="Logo" className='logo'/></Link> */}
        <div className="desktopMenu">
            <Link activeClass='active' to='intro' spy={true} smooth={true} offset={-200} duration={500} className="desktopMenuListItem">Home</Link>
            
            <Link activeClass='active' to='aboutus' spy={true} smooth={true} offset={-120} duration={500} className="desktopMenuListItem">Über Uns</Link>
            <Link activeClass='active' to='events' spy={true} smooth={true} offset={-150} duration={500} className="desktopMenuListItem">Termine</Link>
            <Link activeClass='active' to='contact' spy={true} smooth={true} offset={-120} duration={500} className="desktopMenuListItem">Kontakt</Link>    
            <NavLink activeClass='active' to='/partner' spy={true} smooth={true} offset={-120} duration={500} className="desktopMenuListItem1">Partner</NavLink>
            <NavLink activeClass='active' to='/bilder' spy={true} smooth={true} offset={-120} duration={500} className="desktopMenuListItem1">Bilder</NavLink>
            <NavLink activeClass='active' to='/videos' spy={true} smooth={true} offset={-120} duration={500} className="desktopMenuListItem1">Videos</NavLink>

            {/* <Link activeClass='active' to='downloads' spy={true} smooth={true} offset={-50} duration={500} className="desktopMenuListItem">Downloads</Link> */}
        </div>
        <NavLink style={{ textDecoration: 'none'}} to='/downloads'>
          <button className="desktopMenuBtn" onClick={() => {
            document.getElementById('contact').scrollIntoView({behavior: 'smooth'});
          }}>
              <img src={contactImg} alt="" className="desktopMenuImg" />Downloads</button>
        </NavLink>
        {/* <button className="desktopMenuBtn" onClick={() => {
          document.getElementById('contact').scrollIntoView({behavior: 'smooth'});
        }}>
            <img src={contactImg} alt="" className="desktopMenuImg" />Kontaktiere Uns</button> */}


        <img src={menu} alt="Menu" className='mobMenu1'onClick={()=>setShowMenu(!showMenu)}/>
        <div className="navMenu" style={{display: showMenu? 'flex':'none'}}>
            <Link activeClass='active' to='intro' spy={true} smooth={true} offset={-200} duration={500} className="listItem" onClick={()=>setShowMenu(false)}>Home</Link>
            <Link activeClass='active' to='aboutus' spy={true} smooth={true} offset={-150} duration={500} className="listItem" onClick={()=>setShowMenu(false)}>Über Uns</Link>
            <Link activeClass='active' to='events' spy={true} smooth={true} offset={-150} duration={500} className="listItem" onClick={()=>setShowMenu(false)}>Termine</Link>
            <Link activeClass='active' to='contact' spy={true} smooth={true} offset={-150} duration={500} className="listItem" onClick={()=>setShowMenu(false)}>Kontakt</Link>
            {/* <Link activeClass='active' to='contact' spy={true} smooth={true} offset={-50} duration={500} className="listItem" onClick={()=>setShowMenu(false)}>Contact</Link> */}

            <NavLink activeClass='active' to='/partner' spy={true} smooth={true} offset={-120} duration={500} className="listItem">Partner</NavLink>
            <NavLink activeClass='active' to='/bilder' spy={true} smooth={true} offset={-120} duration={500} className="listItem">Bilder</NavLink>
            <NavLink activeClass='active' to='/videos' spy={true} smooth={true} offset={-120} duration={500} className="listItem">Videos</NavLink>

            <NavLink className="listItem" style={{ textDecoration: 'none'}} to='/downloads'>Downloads
              {/* <button className="listItem" onClick={() => {
              document.getElementById('contact').scrollIntoView({behavior: 'smooth'});
              }}></button> */}
            </NavLink>

        </div>
    </nav>
  )
}
