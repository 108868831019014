import { Navbar } from "./components/NavBar/Navbar";
import { Intro } from "./components/Intro/Intro";
/* import { Skills } from "./components/Skills/Skills"; */
/* import { Works } from "./components/Works/Works"; */
import { Contact } from "./components/Contact/Contact";
import { Footer } from "./components/Footer/Footer";
import { AboutUs } from "./components/AboutUs/aboutus";
import { Downloads } from "./components/Downloads/Downloads";
import { Events } from "./components/Events/Events";
import { Impressum } from "./components/Impressum/Impressum";
import { NavbarSite } from "./components/NavbarSite/NavbarSite";
import { Tauernsound } from "./components/Tauernsound/Tauernsound";
import { Datenschutz } from "./components/Datenschutz/Datenschutz";
import { Partner } from "./components/Partner/Partner";
import { ImagesAndVideos } from "./components/ImagesAndVideos/ImagesAndVideos";
import { Videos } from "./components/Videos/Videos";

import {BrowserRouter,Routes,Route} from 'react-router-dom';

import ScrollToTop from "./components/ScrollToTop";

function App() {


  
  return (
    <div className="App">
      {/* <Navbar/>
      <Intro/>
      <AboutUs/>
      <Events/>
      <Downloads/> */}
      {/* <Works/> */}
      {/* <Skills/> */}
      {/* <Contact/>
      <Footer/> */}

      {/* <BrowserRouter> */}
      {/* <Navbar/> */}
      <Routes>
        <Route path='/' element={[<Navbar/>, <Intro/>, <AboutUs/>, <Events/>, <Contact/>]}/>

        <Route path='/downloads' element={[<NavbarSite/>, <Downloads/>]}/>
        <Route path='/impressum' element={[<NavbarSite/>, <Impressum/>]}/>
        <Route path='/tauernsound' element={[<NavbarSite/>, <Tauernsound/>]}/>
        <Route path='/datenschutz' element={[<NavbarSite/>, <Datenschutz/>]}/>
        
        <Route path='/partner' element={[<NavbarSite/>, <Partner/>]}/>
        <Route path='/bilder' element={[<NavbarSite/>, <ImagesAndVideos/>]}/>
        <Route path='/videos' element={[<NavbarSite/>, <Videos/>]}/>

        <Route path='/downloads' element={<Downloads/>}/>
        <Route path='/impressum' element={<Impressum/>}/>
        
      </Routes>
      <Footer/>
      {/* </BrowserRouter> */}

      <ScrollToTop />
    </div>
  );
}

export default App;
