import React, { useRef } from 'react';
import './contact.css';
import TauernsoundLogo from '../../assets/Tauernsound_Logo.png';
import FacebookIcon from '../../assets/facebook-icon.png';

import YouTubeIcon from '../../assets/youtube.png';
import InstagramIcon from '../../assets/instagram.png';
import emailjs from '@emailjs/browser';

import { NavLink } from 'react-router-dom';

export const Contact = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_dm979ls', 'template_uk42v4l', form.current, 'On8_T0T4q0MdwPMQy')
          .then((result) => {
              console.log(result.text);
              alert('Email Sent !');
              e.target.reset();
            }, (error) => {
              console.log(error.text);
            });
      };
  return (
    <section id="contactPage">
        {/* <div id="clients">
            <h1 className="contactPageTitle">My Clients</h1>
            <p className="clientDesc">
                I have had opportunity to work with a diverse group of companies. 
                Some of the notable companies I have worked with includes
            </p>
            <div className="clientImgs">
                <img src={Walmart} alt="Client" className="clientImg" />
                <img src={Adobe} alt="Client" className="clientImg" />
                <img src={Microsoft} alt="Client" className="clientImg" />
                <img src={Facebook} alt="Client" className="clientImg" />
            </div>
        </div> */}
        <div id="contact">
            <h1 className="contactPageTitle">Kontakt</h1>
            <span className="contactDesc">Email: ersthelfer.band@gmail.com &nbsp;&nbsp;&nbsp;&nbsp;<br/><a className='contactphone' href="tel:+43-8859-2786">Lukas Sattler: 0664 88592786</a> </span>
            <form className='contactForm' ref={form} onSubmit={sendEmail}>
                <input required type="text" className="name" placeholder='Name' name='from_name' />
                <input required type="email" className="email" placeholder='Email' name='from_email' />
                <input required type="text" className="tel" placeholder='Telefonnummer' name='from_tel' />
                <input required type="text" className="datum" placeholder='Datum' name='from_datum' />
                <div className='twoinone'>
                    <input required type="text" className="veranstaltung" placeholder='Veranstaltung' name='from_veranstaltung' />
                    <input required type="text" className="ort" placeholder='Veranstaltungsort' name='from_ort' />
                </div>
                <div className='twoinone'>
                    <input required type="number" className="anzahl" placeholder='Personenanzahl' name='from_anzahl' />
                    <input required type="text" className="spieltauer" placeholder='Spieldauer (von bis)' name='from_spieltauer' />
                </div>
                <textarea required className='msg' name="message" rows="5" placeholder='Text'> </textarea>
                <button type='submit' value='Send' className="submitBtn">Senden</button>
                <div className="links">
                
                    <a href="https://www.facebook.com/people/Ersthelfer/61562495922686/" target="_blank"><img src={FacebookIcon} alt="Facebook" className="link" /></a>
                    {/* <img src={TwitterIcon} alt="Twitter" className="link" /> https://www.facebook.com/ersthelfer.band*/}
                    <a href="https://www.instagram.com/ersthelfer.band/" target="_blank"><img src={InstagramIcon} alt="Instagram" className="link" /></a>
                    <a href="https://www.youtube.com/channel/UCrFbCgg0WLE2sqxIcBjWXfw" target="_blank"><img src={YouTubeIcon} alt="YouTube" className="link" /></a>
                    
                    
                </div>

                <div className="tauernsoundLinks">
                    <NavLink style={{ textDecoration: 'none'}} to='/tauernsound'>
                    <img src={TauernsoundLogo} alt="TauernsoundLogo" className="tauernsoundLink" />
                    </NavLink>
                </div>
            </form>
        </div>
    </section>
  )
}
