import React from 'react';
import './footer.css';

import Sponsor1 from '../../assets/Sponsor_PromoSign.jpg';
import Sponsor2 from '../../assets/Sponsor_SeniorenheimWindegger.png';
import Sponsor3 from '../../assets/Sponsor_Confida.png';
import Sponsor4 from '../../assets/Sponsor_Haigl.png';

import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <footer className='footer'>

      <div className="mobsponsoren">
        <img src={Sponsor2} alt="Sponsor Promo Sign" className="sponsor" />
        {/* <img src={Sponsor1} alt="Sponsor Promo Sign" className="sponsor" /> */}
        
        <img src={Sponsor3} alt="Sponsor Promo Sign" className="sponsor" />
        {/* <img src={Sponsor4} alt="Sponsor Promo Sign" className="sponsor" />  */}
      </div>


      <div className="sponsoren1">
        <img src={Sponsor2} alt="Sponsor Promo Sign" className="sponsor" />
        {/* <img src={Sponsor1} alt="Sponsor Promo Sign" className="sponsor" /> */}
        
      </div>

        <p className='footertext'>Copyright &#169; 2024 Ersthelfer Alle Rechte vorbehalten.</p>
        
        <ul className="footermenu">
            {/* <Link className='footerlink' style={{ textDecoration: 'none'}} to='/downloads'>Downloads</Link> */}
            <Link className='footerlink' style={{ textDecoration: 'none'}} to='/impressum'>Impressum</Link>
            <Link className='footerlink' style={{ textDecoration: 'none'}} to='/datenschutz'>Datenschutz</Link>
        </ul>

        <div className="sponsoren2">
        <img src={Sponsor3} alt="Sponsor Promo Sign" className="sponsor" />
        {/* <img src={Sponsor4} alt="Sponsor Promo Sign" className="sponsor" />  */}
      </div>

      

    </footer>
  )
}
