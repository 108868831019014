import React from 'react';
import './events.css';

import Events2 from '../../events.json';

import { useState } from "react";
import * as XLSX from "xlsx";

export const Events = () => {

  const [data, setData] = useState([]);

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary"});
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      setData(parsedData);
    };
  }


  return (
        
        <section id="events">

              {/* <input type="file" accept='.xlsx, .xls' onChange={handleFileUpload} />
              <button onClick={handleFileUpload}>Clickkkk</button> */}

              {/* {data.length > 0 && (
                <table className='eventsTable'>
                  <thead>
                    <tr>
                      {Object.keys(data[0]).map((key) => 
                      <th key={key}>{key}</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                      {data.map((row, index) => (
                        <tr key={index}>
                          {Object.values(row).map((value, index) => (
                            <td key={index}>{value}</td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              )} */}

                <h1 className="eventsPageTitle">Termine 2024/2025</h1>

                <table className='eventsTable'>
                  <tbody>
                    {
                  
                      Events2.map( events => {
                        return(
                          <tr>
                          <td className="eventDate">{events.date}</td>
                          <td className="eventTime">{events.time}</td>
                          <td className="eventName">{events.name}</td>
                          <td className="eventLocation">{events.location}</td>
                          </tr>
                        )
                      } )
                  
                    }
                  </tbody>
                </table>

                <table className='mobEventsTable'>
                  <tbody>
                    {
                  
                      Events2.map( events => {
                        return(
                          <tbody>
                          <tr className='mobtr1'>
                              <td className="mobEventDate">{events.date}</td>
                              <td className="mobEventTime">{events.time}</td>
                          </tr>
                          <tr className='mobtr2'>
                            <td className="mobEventName">{events.name}</td>
                            <td className="mobEventLocation">{events.location}</td>
                          </tr>

                          </tbody>
                        )
                      } )
                    }
                    
                  </tbody>
                </table>


                {/* <table className='eventsTable'>
                  <tbody>
                    <tr>
                      <td className="eventDate">03. Mai 2024</td>
                      <td className="eventTime">22:00 Uhr</td>
                      <td className="eventName">Marktfest</td>
                      <td className="eventLocation">Oberzeiring</td>
                    </tr>
                    <tr> 
                      <td className="eventDate">08. Juni 2024</td>
                      <td className="eventTime">21:00 Uhr</td>
                      <td className="eventName">Teichfest</td>
                      <td className="eventLocation">Möderbrugg</td>
                    </tr>
                    <tr>
                      <td className="eventDate">14. Juni 2024</td>
                      <td className="eventTime">21:00 Uhr</td>
                      <td className="eventName">Konzert</td>
                      <td className="eventLocation">Götzendorf</td>
                    </tr>
                    <tr> 
                      <td className="eventDate">06. Juli 2024</td>
                      <td className="eventTime">21:00 Uhr</td>
                      <td className="eventName">Feuerwehrfest</td>
                      <td className="eventLocation">Kitzbühel</td>
                    </tr>
                    <tr> 
                      <td className="eventDate">13. Juli 2024</td>
                      <td className="eventTime">20:30 Uhr</td>
                      <td className="eventName">Generationenfest</td>
                      <td className="eventLocation">Ennstal</td>
                    </tr>
                    <tr> 
                      <td className="eventDate">10. August 2024</td>
                      <td className="eventTime"></td>
                      <td className="eventName">Privatveranstaltung</td>
                      <td className="eventLocation"></td>
                    </tr>
                    <tr> 
                      <td className="eventDate">05. Juli 2025</td>
                      <td className="eventTime">21:00 Uhr</td>
                      <td className="eventName">Pumperwaldlfest</td>
                      <td className="eventLocation">Oberzeiring</td>
                    </tr>
                    <tr> 
                      <td className="eventDate">12. Juli 2025</td>
                      <td className="eventTime"></td>
                      <td className="eventName">Privatveranstaltung</td>
                      <td className="eventLocation"></td>
                    </tr>
                  </tbody>
                </table> */}

                {/* <table className='mobEventsTable'>
                  <tbody>
                    <tr className='mobtr1'>
                      <td className="mobEventDate">03. Mai 2024</td>
                      <td className="mobEventTime">22:00 Uhr</td>
                    </tr>
                    <tr className='mobtr2'>
                      <td className="mobEventName">Marktfest</td>
                      <td className="mobEventLocation">Oberzeiring</td>
                    </tr>
                    <tr className='mobtr1'> 
                      <td className="mobEventDate">08. Juni 2024</td>
                      <td className="mobEventTime">21:00 Uhr</td>
                    </tr>
                    <tr className='mobtr2'>
                      <td className="mobEventName">Teichfest</td>
                      <td className="mobEventLocation">Möderbrugg</td>
                    </tr>
                    <tr className='mobtr1'>
                      <td className="mobEventDate">14. Juni 2024</td>
                      <td className="mobEventTime">21:00 Uhr</td>
                    </tr>
                    <tr className='mobtr2'>
                      <td className="mobEventName">Konzert</td>
                      <td className="mobEventLocation">Götzendorf</td>
                    </tr>
                    <tr className='mobtr1'> 
                      <td className="mobEventDate">06. Juli 2024</td>
                      <td className="mobEventTime">21:00 Uhr</td>
                    </tr>
                    <tr className='mobtr2'>
                      <td className="mobEventName">Feuerwehrfest</td>
                      <td className="mobEventLocation">Kitzbühel</td>
                    </tr>
                    <tr className='mobtr1'> 
                      <td className="mobEventDate">13. Juli 2024</td>
                      <td className="mobEventTime">20:30 Uhr</td>
                    </tr>
                    <tr className='mobtr2'>
                      <td className="mobEventName">Generationenfest</td>
                      <td className="mobEventLocation">Ennstal</td>
                    </tr>
                    <tr className='mobtr1'> 
                      <td className="mobEventDate">10. August 2024</td>
                      <td className="mobEventTime"></td>
                    </tr>
                    <tr className='mobtr2'>
                      <td className="mobEventName">Privatveranstaltung</td>
                      <td className="mobEventLocation"></td>
                    </tr>
                    <tr className='mobtr1'> 
                      <td className="mobEventDate">05. Juli 2025</td>
                      <td className="mobEventTime">21:00 Uhr</td>
                    </tr>
                    <tr className='mobtr2'>
                      <td className="mobEventName">Pumperwaldlfest</td>
                      <td className="mobEventLocation">Oberzeiring</td>
                    </tr>
                    <tr className='mobtr1'> 
                      <td className="mobEventDate">12. Juli 2025</td>
                      <td className="mobEventTime"></td>
                    </tr>
                    <tr className='mobtr2'>
                      <td className="mobEventName">Privatveranstaltung</td>
                      <td className="mobEventLocation"></td>
                    </tr>
                  </tbody>
                </table> */}
        </section>
  )
}
