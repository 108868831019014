import React from 'react';
import './downloads.css';
import downloadImg from '../../assets/Ersthelfer_Download.png';

export const Downloads = () => {
  return (
        <section id="downloads">
            <h1 className='downloadsTitle'>Download Files</h1>

            <div className="downloadbtns">
            <a className="downloadbtn" href='Ersthelfer_Logos.zip' download="ErsthelferLogos.zip">
            <img src={downloadImg} alt="" className="downloadimg" />Logos
            </a>
            <a className="downloadbtn" href='Ersthelfer_Fotos.zip' download="ErsthelferFotos.zip">
            <img src={downloadImg} alt="" className="downloadimg" />Fotos
            </a>
            </div>
            {/* <div className="downloadbtns">
            <a className="downloadbtn" href='Ersthelfer_Logos.zip' download="ErsthelferLogos.zip">
            <img src={downloadImg} alt="" className="downloadimg" />Programm
            </a>
            </div> */}
        </section>
  )
}
