import React from 'react';
import './intro.css';
import bg from '../../assets/Ersthelfer_Foto_00.png';
import bgmob from '../../assets/Ersthelfer_Foto_Mobile.png';

export const Intro = () => {
  return (

        <section id="intro">
            {/* <div className="introContent">
                <span className="hello">Hello,</span>
                <span className="introText">I'm <span className="introName">Smith</span> <br />Website Designer</span>
                <p className="introPara">I am a skilled web designer with experience in creating <br />visually appealing and user friendly websites.</p>
                <Link><button className="btn"><img src={btnImg} alt="Hire" className='btnImg' />Hire me</button></Link>
            </div> */}
            <img src={bg} alt="Profile" className='bg' />
            <img src={bgmob} alt="Profile" className='mobbg' />
              
            
            
        </section>
  )
}
