import React from 'react';
import './impressum.css';

export const Impressum = () => {
  return (
        <section id="impressum">
            <h1 className='impressumTitle'>Impressum</h1>
            <div className="impressumTexte">
              <p className="impressumText">Ersthelfer</p>
              <p className="impressumText">Sattler Lukas & Johann Spiegel</p>
              <p className="impressumText">Bretstein vor der Kirche 13</p>
              <p className="impressumText">8763 Pölstal</p>
            </div>
        </section>
  )
}
